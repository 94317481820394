import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HomeHero from "../components/homeHero";
import { FaTree } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaBolt } from "react-icons/fa";
import { FaStethoscope } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaRecycle } from "react-icons/fa";
import { motion } from "framer-motion";

function AboutPage({ data, location }) {
	let aboutPage = data.aboutContent.edges[0].node.frontmatter;
	let aboutBody = data.aboutContent.edges[0].node.html;
	console.log(location);
	return (
		<Layout>
			<SEO
				keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
				title="About"
			/>
			<HomeHero
				headline={aboutPage.aboutBannerHeadline}
				copy={aboutPage.aboutBannerHeadText}
				phone={aboutPage.aboutBannerPhone}
				image={aboutPage.aboutBannerImage.childImageSharp.fluid}
			/>
			<div className="pattern-bg relative max-w-7xl mx-auto bg-gray-200">
				<div className="mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 0.8,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 1.2,
								},
							},
						}}
					>
						<h2 className="text-center text-4xl px-4 leading-tight font-heading">
							What Does LP Tree Service Offer?
						</h2>
					</motion.div>
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 1,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 1.4,
								},
							},
						}}
					>
						<div
							style={{ columnGap: "2rem" }}
							className="mt-6 grid gap-0.5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:mt-4"
						>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaTree style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service1title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service1copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaTree style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service2title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service2copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaCheck style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service3title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service3copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaBolt style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service4title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service4copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaStethoscope style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service5title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service5copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaStethoscope style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service6title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service6copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaTree style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service7title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service7copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaHome style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service8title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service8copy}
									</p>
								</div>
							</div>
							<div className="col-span-1 flex justify-center py-4 bg-gray-50">
								<div className="rounded-lg shadow-lg bg-white p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										<FaRecycle style={{ display: "inline" }} />
										<span style={{ display: "inline", marginLeft: "5px" }}>
											{" "}
											{aboutPage.service9title}
										</span>
									</h3>
									<p className="text-base leading-6 text-gray-600 font-light">
										{aboutPage.service9copy}
									</p>
								</div>
							</div>
						</div>
					</motion.div>
				</div>
				<div className="relative max-w-7xl mx-auto paul-ranum bg-white">
					<div className="wysiwyg border-t-2 border-gray-100 pt-20 max-w-xl px-4 mx-auto pb-10 text-base leading-6">
						<h2 className="text-left text-4xl leading-tight font-heading mb-4 text-gray-900">
							The History of LP Tree Service
						</h2>
						<div
							className="wysiwyg text-base leading-6 text-gray-900 font-light"
							dangerouslySetInnerHTML={{ __html: aboutBody }}
						></div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AboutPage;

export const aboutQuery = graphql`
	query {
		aboutContent: allMarkdownRemark(
			filter: { frontmatter: { pageName: { eq: "about" } } }
		) {
			edges {
				node {
					frontmatter {
						aboutBannerHeadline
						aboutBannerHeadText
						aboutBannerPhone
						aboutBannerImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
						aboutSecondImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
						service1title
						service1copy
						service2title
						service2copy
						service3title
						service3copy
						service4title
						service4copy
						service5title
						service5copy
						service6title
						service6copy
						service7title
						service7copy
						service8title
						service8copy
						service9title
						service9copy
					}
					rawMarkdownBody
					id
					html
				}
			}
		}
	}
`;
